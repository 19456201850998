import { type TypedUseSelectorHook, useSelector } from 'react-redux';
import {
    type appBannerReducer,
    type cartReducer,
    type currencyReducer,
    type drawerReducer,
    type headerReducer,
} from 'dibs-buyer-layout/exports/dblReducers';
import { type MeasurementUnit } from 'dibs-intl/exports/units';

export type DefaultReducersReduxState = {
    appBanner: ReturnType<typeof appBannerReducer>;
    cart: ReturnType<typeof cartReducer>;
    currency: ReturnType<typeof currencyReducer>;
    drawer: ReturnType<typeof drawerReducer>;
    displayPreferences: {
        showMeasurements: boolean;
    };
    header: ReturnType<typeof headerReducer>;
    filters: {
        filtersShown: boolean;
        generatedUriRef: string | null;
        page: number;
        refetchInFlight: boolean;
        zipCode: string;
        userZipCode: string;
        hasNewFilterUpdates: boolean;
        triggerType: string | null;
    };
    measurementUnit: MeasurementUnit;
    relayVariables: {
        variables: {
            afterDisplay?: boolean;
            afterDisplayOrBuaSsr?: boolean;
            first?: number;
            showLoading?: boolean;
            userId?: string;
            personalizationId?: string;
            itemId?: string;
            isClient: boolean;
            isMultiSku?: boolean;
            fetchAuction?: boolean;
            fetchAuctionOrBuaSsr?: boolean;
            fetchUser?: boolean;
            isTrade?: boolean;
            priceBookName: string;
            updateAuctionItem?: boolean;
            updatedUserCountryCode?: string;
            updateItemPricing?: boolean;
            userZipCode?: string;
            includeSponsoredItems?: boolean;
            userCountryCode?: string;
            followSearchPages?: (string | null)[];
            showSeller: boolean;
            isPDPDesktopAggregationVariant?: boolean;
            regionsList?: string[] | null;
        };
    };
    ringMeasurementType: string;
};

export const useSbSelector: TypedUseSelectorHook<DefaultReducersReduxState> = useSelector;
